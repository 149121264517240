<template>
  <div>
    <div
      class="product-dialog"
      v-for="(product, index) in products"
      :key="product.productId"
    >
      <div v-if="popup" class="d-flex pa-3 mb-4">
        <span class="name font-weight-bold align-self-center">
          {{ product.name }}
        </span>
      </div>
      <v-tabs
        v-model="tab[index]"
        icons-and-text
        background-color="grey lighten-3"
        center-active
        slider-size="3"
        height="60"
        active-class="selected-tab"
        class="primary product-leaflet-tabs"
      >
        <v-tab href="#product"
          ><span class="font-weight-bold caption mb-0 pt-1">{{
            $t("leaflet.productDetail.product")
          }}</span
          ><v-icon class="circle-icon" color="white" small
            >icon-article</v-icon
          ></v-tab
        >
        <!-- <v-tab href="#info">
        <span class="font-weight-bold caption mb-0 pt-1">{{
          $t("leaflet.productDetail.info")
        }}</span
        ><v-icon class="circle-icon" color="white" small>icon-info</v-icon>
      </v-tab> -->
        <v-tab v-if="showMetadata(product) && videoURl(product)" href="#play"
          ><span class="font-weight-bold caption mb-0 pt-1">{{
            $t("leaflet.productDetail.video")
          }}</span
          ><v-icon class="circle-icon" color="white" small
            >icon-play</v-icon
          ></v-tab
        >
        <v-tab
          v-if="showMetadata(product) && showRecipeTab(product)"
          href="#ricetta"
          ><span class="font-weight-bold caption mb-0 pt-1">{{
            $t("leaflet.productDetail.recipe")
          }}</span
          ><v-icon class="circle-icon" color="white" small
            >icon-ricetta</v-icon
          ></v-tab
        >
        <v-tab v-if="showMetadata(product) && showInfoTab(product)" href="#bulb"
          ><span class="font-weight-bold caption mb-0 pt-1">{{
            $t("leaflet.productDetail.curiosity")
          }}</span
          ><v-icon class="circle-icon" color="white" small>$bulb</v-icon></v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab[index]">
        <v-tab-item value="product">
          <v-card
            rounded="md"
            class="product-card product-card-detail"
            :class="[promoClass]"
            :id="`product-card-${product.slug}`"
          >
            <div class="product px-3">
              <div class="d-flex flex-column body w-100">
                <img
                  :src="product.media[0].medium"
                  onerror="this.onerror=null;this.src='/no-icon.png'"
                  class="product-img align-self-center"
                  :alt="'Immagine' + product.name"
                  :title="
                    product.name +
                      ' (' +
                      product.codInt +
                      '-' +
                      product.codVar +
                      ')'
                  "
                />

                <div
                  class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
                >
                  <span class="cod-int text-caption d-none">
                    cod. {{ product.codInt }}
                  </span>
                  <span class="short_descr">
                    {{ product.shortDescr }}
                  </span>
                  <span class="descr">
                    {{ product.description }}
                    <span
                      class="weight-unit"
                      v-if="
                        product.productInfos &&
                          product.productInfos.TIPOLOGIA == 'Pezzo' &&
                          product.priceUmDisplay
                      "
                    >
                      - {{ $n(product.priceUmDisplay, "currency") }}/{{
                        product.weightUnitDisplay
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="actions" @click.stop.prevent>
                <!--  -->
                <ProductPriceLeaflet
                  v-if="product.priceDisplay"
                  :product="product"
                />
                <v-spacer />
                <v-tooltip left :key="heartKey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="addToList({ leaflet, product })"
                      @mousedown.stop
                      color="primary"
                      aria-label="Aggiungi alla lista della spesa"
                      class="pb-1"
                    >
                      <v-icon large>icon-lista</v-icon>
                      <v-icon small class="mt-6 plus-icon">$plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("product.addToList") }}</span>
                </v-tooltip>
              </div>
            </div>
            <ProductDescription
              :panelOpen="showAccordion"
              :product="product"
              :key="'product_description_' + product.productId + '_key_' + key"
            />
          </v-card>
        </v-tab-item>
        <!-- <v-tab-item value="info">
        <ProductDescription
          :product="product"
          :key="'product_description_' + product.productId + '_key_' + key"
        />
      </v-tab-item> -->
        <v-tab-item value="play">
          <div class="leaflet-video-wraper">
            <iframe
              class="leaflet-iframe rounded-sm"
              :src="videoURl(product)"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-tab-item>
        <v-tab-item value="ricetta">
          <div class="pa-3">
            <ebsn-meta
              :target="product"
              path="metaData.product_leaflet.RECIPE"
              tag="div"
            ></ebsn-meta>
          </div>
        </v-tab-item>
        <v-tab-item value="bulb">
          <div class="leaflet-curiosity-wraper pa-3">
            <ebsn-meta
              :target="product"
              path="metaData.product_leaflet.INFO"
              tag="div"
            ></ebsn-meta>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<style lang="scss">
.product-card-detail {
  // .warehouse-promo-price .product_promo {
  //   flex-direction: column;
  // }
  .product-img {
    width: 300px;
    height: 300px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .product .description {
    height: fit-content;
    max-height: fit-content;
    .name {
      font-family: $heading-font-family;
    }
  }
  .plus-icon {
    height: 20px !important;
    width: 20px !important;
    padding: 2px;
    font-size: 15px;
    font-weight: bold;
    color: $white !important;
    border-radius: 50%;
    background-color: var(--v-primary-base);
  }
  .promo-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
  .cart-item-info {
    margin-bottom: -21px;
  }

  .top {
    height: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }
}
.product-leaflet-tabs {
  .v-tab {
    padding: 0 8px;
    opacity: 0.5;
  }
  .selected-tab {
    opacity: 1;
  }
  .v-tabs-slider {
    margin-top: -57px;
  }
  .v-slide-group__content {
    padding-left: 12px;
  }
  .circle-icon {
    margin-top: 5px;
    background-color: $primary;
    padding: 6px;
    border-radius: 50%;
  }
  .v-carousel .v-window-item {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .v-carousel-item {
    height: auto;
  }
}
.leaflet-video-wraper {
  padding: 10px;
  .leaflet-iframe {
    width: 100%;
    height: 30vh;
  }
}
.leaflet-curiosity-wraper img {
  width: 100%;
  height: auto;
}

.product-dialog .product-description {
  padding-bottom: 0 !important;
}
.product-dialog:not(:only-child) {
  margin-bottom: 50px;
}
</style>
<script>
import ProductPriceLeaflet from "../product/ProductPriceLeaflet.vue";
import ProductDescription from "@/components/product/ProductDescription.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters, mapActions } from "vuex";

import get from "lodash/get";
import has from "lodash/has";

export default {
  name: "ProductCard",
  props: {
    products: { type: Array, required: true },
    leaflet: { type: Object, required: true },
    position: { type: Number, default: undefined },
    popup: { type: Boolean, default: true },
    icon: { type: String, required: false },
    selectedTab: {
      type: String,
      default: "product"
    }
  },
  components: {
    ProductPriceLeaflet,
    ProductDescription
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0,
      tab: [],
      product: null
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    showAccordion() {
      return this.products.length > 1 ? "" : 0;
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product && this.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.product && this.product.badge) {
        productClasses.push(this.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    ...mapActions({ addToList: "custom/addToLeafletList" }),
    giftAdded() {
      this.plus();
    },

    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.value;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      console.log(this.products);
      this.$emit("selectProduct");
    },
    loadURL(videoUrl) {
      if (videoUrl) {
        const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
        const vimeoEmbedTemplate = "https://player.vimeo.com/video/";

        const isYouTubeVideo =
          videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be");
        const isVimeoVideo = videoUrl.includes("vimeo.com");

        if (isYouTubeVideo) {
          const url = videoUrl.split(
            /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
          );
          const YId =
            undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];

          if (YId === url[0]) {
            return videoUrl;
          } else {
            const youtubeEmbedUrl = youtubeEmbedTemplate.concat(YId);
            console.log(youtubeEmbedUrl);
            return youtubeEmbedUrl;
          }
        } else if (isVimeoVideo) {
          const url = videoUrl.split(
            /(https:\/\/(?:vimeo\.com\/|.*\/videos\/))(\d+)/
          );
          const vimeoId = url[2];

          if (vimeoId === url[0]) {
            return videoUrl;
          } else {
            const vimeoEmbedUrl = vimeoEmbedTemplate.concat(vimeoId);
            return vimeoEmbedUrl;
          }
        } else {
          return videoUrl;
        }
      } else {
        return null;
      }
    },

    videoURl(product) {
      return this.loadURL(get(product, "metaData.product_leaflet.VIDEO", null));
    },
    showInfoTab(product) {
      return has(product, "metaData.product_leaflet.INFO");
    },
    showRecipeTab(product) {
      return get(product, "metaData.product_leaflet.RECIPE");
    },
    showMetadata(product) {
      return get(product, "metaData.product_leaflet.SHOW_LEAFLET_METADATA");
    }
    // async getProductDetails() {
    //   this.productDetails = await ProductService.getProductBySlug(
    //     this.product.slug
    //   );
    //   console.log("Details", this.productDetails);
    // }
  },
  mounted() {
    if (this.icon) {
      this.tab[0] = this.icon;
    } else {
      this.tab[0] = 0;
    }
  }
};
</script>
