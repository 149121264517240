<template>
  <v-container class="px-md-10" v-if="productDescription">
    <v-expansion-panels v-model="panel" flat multiple>
      <v-expansion-panel
        v-for="(panelData, index) in productDescription"
        :key="index"
      >
        <v-expansion-panel-header>
          <h4>{{ panelData?.NOME_SCHEDA }}</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- If the panel is nutritional data-->
          <template v-if="isNutritionalData(panelData.NOME_SCHEDA)">
            <h6 v-if="panelData?.values[0]?.value">
              {{ panelData?.values[0]?.label }}
            </h6>
            <table v-if="panelData?.values[0]?.value" class="w-100">
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="header in getHeaders(panelData)"
                    :key="header.ID_VALORE"
                  >
                    {{ header.NOME }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(nutrientItems, nutrientName) in getGroupedNutrients(
                    panelData
                  )"
                  :key="nutrientName"
                >
                  <td class="font-weight-bold">{{ nutrientName }}</td>
                  <td
                    v-for="header in getHeaders(panelData)"
                    :key="header.ID_VALORE"
                  >
                    {{ getNutrientValue(nutrientItems, header.ID_VALORE) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <!-- For non-nutritional -->
          <template v-else>
            <div v-for="(item, index) in panelData.values" :key="index">
              <template v-if="item.value && !Array.isArray(item.value)">
                <h6 :class="index > 0 ? 'mt-6' : ''">{{ item.label }}</h6>
                <span>{{ item.value }}</span>
              </template>

              <template v-else-if="Array.isArray(item.value)">
                <!-- has allergy section? -->
                <template v-if="isAllergies(item.label)">
                  <h6 :class="index > 0 ? 'mt-6' : ''">{{ item.label }}</h6>
                  <div
                    v-for="(names, groupKey) in groupAllergies(item.value)"
                    :key="groupKey"
                  >
                    {{ groupKey }}: {{ names.join(", ") }}
                  </div>
                </template>

                <template v-else>
                  <h5 :class="index > 0 ? 'mt-6' : ''">{{ item.label }}</h5>
                  <div
                    v-for="(subItem, subIndex) in item.value"
                    :key="subIndex"
                  >
                    <template v-if="subItem.value">
                      <h6>{{ subItem.label }}</h6>
                      <span>{{ subItem.value }}</span>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}

table tr td {
  padding: 10px;
}
</style>

<script>
export default {
  name: "ProductDescription",
  data() {
    return {
      panel: [0],
      disabled: false,
      readonly: false
    };
  },
  props: {
    productDescription: {
      type: Array,
      required: false
    }
  },
  //computed: {},
  methods: {
    isNutritionalData(name) {
      return name && name.toLowerCase() === "valori nutrizionali";
    },
    isAllergies(name) {
      return name && name.toLowerCase() === "allergeni";
    },
    getHeaders(panelData) {
      const data = panelData?.values[0]?.value || [];
      return data.filter(
        item => item.CODICE_NOME === "H" && item.CODICE_VALORE === "HEADER"
      );
    },
    getGroupedNutrients(panelData) {
      const data = panelData?.values[0]?.value || [];
      const nutrients = data.filter(item => item.CODICE_NOME === "N");
      const groups = {};
      nutrients.forEach(item => {
        if (item.NOME) {
          if (!groups[item.NOME]) {
            groups[item.NOME] = [];
          }
          groups[item.NOME].push(item);
        }
      });
      return groups;
    },
    getNutrientValue(nutrientGroup, headerId) {
      const nutrient = nutrientGroup.find(item => item.ID_VALORE === headerId);
      return nutrient ? nutrient.VALORE : "";
    },
    groupAllergies(allergies) {
      const groups = {};
      allergies.forEach(item => {
        const key = item.VALORE;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(item.NOME);
      });
      return groups;
    }
  }
};
</script>
